import DJZT from './sn-table-column-djzt';
const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "设备",
                prop: "SBMC",
                minWidth: 180
            }, {
                label: "监测时间",
                prop: "JCSJ",
                minWidth: 160
            }, {
                label: "ph值",
                prop: "PH"
            }, {
                label: "溶解氧(mg/L)",
                prop: "RJY",
                minWidth: 120
            }, {
                label: "温度(℃)",
                prop: "WD"
            }, {
                label: "浊度(NTU)",
                prop: "ZD",
                minWidth: 120
            }, {
                label: "COD(mg/L)",
                prop: "COD"
            },

                // {
                //     label: "氧化还原电位(orp)",
                //     prop: "YHHYDW",
                //     minWidth: 140
                // },
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        dateData : [{
            isShow: true,
            label: '开始时间',
            clearable: true,
            placeholder: '开始时间',
            operateType: 'search',
            value: 'start',
        },{
            isShow: true,
            label: '结束时间',
            clearable: true,
            placeholder: '结束时间',
            operateType: 'search',
            value: 'end',
        }]
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;